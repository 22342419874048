import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import AppSupportCategory from "./AppSupportCategory";

interface LearnMoreSectionProps {
  country: string;
  page?: string;
}

export const articles = {
  canada: {
    getAPlan: [
      {
        title: "What plans are available with eSIM at PhoneBox?",
        articleId: "yp2nff",
      },
    ],
    eSIM: [
      { title: "What is eSIM? ", articleId: "qx8p3v" },
      { title: "How does eSIM activation work?", articleId: "1mj0l56" },
      { title: "Are all devices compatible with eSIM?", articleId: "k789oq" },
    ],
  },
};

const LearnMoreSection: React.FC<LearnMoreSectionProps> = (
  props: LearnMoreSectionProps
) => {
  return (
    <div>
      <Title>Learn More</Title>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <AppSupportCategory
            articles={articles.canada.getAPlan}
            title="Get a Plan"
          ></AppSupportCategory>
        </Col>
        <Col sm={12} md={12} lg={6}>
          <AppSupportCategory
            articles={articles.canada.eSIM}
            title="eSIM"
          ></AppSupportCategory>
        </Col>
      </Row>
    </div>
  );
};

const Title = styled.p`
  font-size: 200%;
  font-weight: 650;
  margin-bottom: 10px;
`;
export default LearnMoreSection;

import ReactGA from "react-ga4";
import TagManager from "@sooro-io/react-gtm-module";

export const triggerGoogleTagAndAnalytics = (event: string, data = {}) => {
  ReactGA.send({
    hitType: event,
    page: window.location.pathname + window.location.search,
    ...data,
  });
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      page: window.location.pathname + window.location.search,
      ...data,
    },
  });
};

import { constants } from "../reduxConstants";

export const updateLocal = (localObject: any) => (dispatch: any) => {
  dispatch({
    type: constants.UPDATE_LOCAL,
    payload: localObject,
  });
};

export const resetLocalState = () => {
  return {
    type: constants.RESET_LOCAL_STATE,
  };
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import styles from "./css/Footer.module.scss";
import { Col, Container, Row } from "react-bootstrap";

const MobileFooter: React.FC = () => {
  return (
    <div className={styles.navContainer}>
      <div className="container">
        <div className={styles.navWrapper}>
          <div className={styles.navTabsContainer}>
            <Container fluid>
              <Row>
                <Col xs={6}>
                  <div className={styles.navTabItem}>
                    <div
                      onClick={() =>
                        window.open("https://www.gophonebox.com/about-us")
                      }
                      className={styles.navTitleMobile}
                    >
                      About Us
                    </div>
                    <div
                      onClick={() =>
                        window.open(
                          "https://www.gophonebox.com/terms-of-service"
                        )
                      }
                      className={styles.navTitleMobile}
                    >
                      Terms of Service
                    </div>
                    <div
                      onClick={() =>
                        window.open(
                          "https://www.gophonebox.com/terms-of-service/?section=early-cancellation"
                        )
                      }
                      className={styles.navTitleMobile}
                    >
                      Return & Refund
                    </div>
                    {/* COVERAGE MAP */}
                    <div
                      onClick={() =>
                        window.open("https://www.gophonebox.com/coverage-map")
                      }
                      className={styles.navTitleMobile}
                    >
                      Coverage Map
                    </div>
                    <div
                      onClick={() => {
                        // window.localStorage.setItem("isContactUsQuery", 'true');
                        window.open("https://www.gophonebox.com/support");
                      }}
                      className={styles.navTitleMobile}
                    >
                      Contact us
                    </div>
                    <div
                      onClick={() =>
                        window.open("https://www.gophonebox.com/careers")
                      }
                      className={styles.navTitleMobile}
                    >
                      Careers
                    </div>
                    <div
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.phonebox2021"
                        )
                      }
                      className={styles.navTitleMobile}
                    >
                      Download our App
                    </div>

                    <div className={styles.navAppStore}>
                      <div className={styles.navAppWrapper}>
                        <div className={styles.navAppLogos}>
                          <div style={{ color: "#fff", width: 20 }}>CA:</div>
                          <div
                            onClick={() =>
                              window.open(
                                "https://play.google.com/store/apps/details?id=com.phoneboxexpo.SelfServe2024"
                              )
                            }
                          >
                            <img
                              src="/images/google-play-logo.png"
                              alt="google play logo"
                            />
                          </div>
                          <div
                            onClick={() =>
                              window.open(
                                "https://apps.apple.com/ca/app/phonebox-canada/id6503991715?platform=iphone"
                              )
                            }
                          >
                            <img
                              src="/images/app-store.png"
                              alt="apple store logo"
                            />
                          </div>
                        </div>
                        <div className={styles.navAppLogos}>
                          <div style={{ color: "#fff", width: 20 }}>US:</div>
                          <div
                            onClick={() =>
                              window.open(
                                "https://play.google.com/store/apps/details?id=com.phoneboxexpo.phoneboxfrontend"
                              )
                            }
                          >
                            <img
                              src="/images/google-play-logo.png"
                              alt="google play logo"
                            />
                          </div>
                          <div
                            onClick={() =>
                              window.open(
                                "https://apps.apple.com/us/app/phonebox-us/id6446149914?platform=iphone"
                              )
                            }
                          >
                            <img
                              src="/images/app-store.png"
                              alt="apple store logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className={styles.navTabItem}>
                    <div
                      className={styles.navTitle}
                      style={{ marginBottom: "8px" }}
                    >
                      Social Media
                    </div>
                    <div className={styles.navSocialLinks}>
                      <div
                        onClick={() =>
                          window.open("https://www.facebook.com/gophonebox")
                        }
                        className={styles.navSocialLink}
                        style={{ marginBottom: "8px" }}
                      >
                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                      </div>
                      <div
                        onClick={() =>
                          window.open("https://twitter.com/gophonebox")
                        }
                        className={styles.navSocialLink}
                        style={{ marginBottom: "8px" }}
                      >
                        <FontAwesomeIcon icon={faTwitter} /> Twitter
                      </div>
                      <div
                        onClick={() =>
                          window.open("https://www.instagram.com/gophonebox/")
                        }
                        className={styles.navSocialLink}
                        style={{ marginBottom: "8px" }}
                      >
                        <FontAwesomeIcon icon={faInstagram} /> Instagram
                      </div>
                    </div>
                    <div
                      className={styles.navTitle}
                      onClick={() => {
                        window.open("https://www.gophonebox.com/support");
                      }}
                    >
                      Help
                    </div>
                    <div
                      onClick={() => window.open("http://blog.gophonebox.com/")}
                      className={styles.navTitle}
                    >
                      Blog
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className={styles.footerYearMobile}>
                  © {new Date().getFullYear()} Connex Global Communications Inc.
                  o/a PhoneBox. All Rights Reserved.
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;

// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100vw;
// `;

// const Col = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex-grow: 1;
//   margin-right: 3rem;
// `;

/**
 * Custom API error codes from Proxy API.
 */
export enum ApiErrorCode {
  PasswordResetTokenExpired = 1000,
  AccountVerificationTokenExpired = 1001,
  AccountNotFound = 1002,
  UnableToSendPasswordResetEmail = 1003,
  PasswordResetWasNotRequested = 1004,
  AccountNotVerified = 1005,
  UnableToSendAccountVerificationEmail = 1006,
  UsernameEmailOrPasswordIncorrect = 1007,
  PasswordsDoNotMatch = 1008,
  PaymentNotCompleted = 1009,
}

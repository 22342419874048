import { constants } from "../reduxConstants";

export const updateActivationInfo = (shopObject: any) => (dispatch: any) => {
  dispatch({
    type: constants.UPDATE_ACTIVATION_INFO,
    payload: shopObject,
  });
};

export const resetActivationInfoState = () => {
  return {
    type: constants.RESET_ACTIVATION_INFO_STATE,
  };
};

import { useDispatch, useSelector } from "react-redux";
import { updateLocal } from "../redux/actions/localActions";
import { updateActivationInfo } from "../redux/actions/activationInfoActions";
import { DataRow } from "../types/models/DataRow";
import { RootState } from "../redux/store";
import { colors, constants } from "../utilities/constants";
import PlanPrice from "./PlanPrice";
import styled from "styled-components";

interface PlanSelectCardProps {
  plan: DataRow;
  onClickNext: () => void;
  style?: React.CSSProperties;
}

export default function PlanSelectCard(props: PlanSelectCardProps) {
  const dispatch = useDispatch();
  const localVars = useSelector((state: RootState) => state.local);
  const selectedPlanId = localVars.selectedPlanId;
  const country = localVars.country;
  const isCanada = country === constants.CANADA;
  const currency = isCanada
    ? constants.CANADA_CURRENCY
    : constants.USA_CURRENCY;
  const countryColor = isCanada ? colors.BLUE : colors.GREEN;
  const transparentCountryColor = isCanada
    ? colors.TRANSPARENTBLUE
    : colors.TRANSPARENTGREEN;
  const lightCountryColor = isCanada ? colors.LIGHTBLUE : colors.LIGHTGREEN;

  const isSelected = props.plan.planId === selectedPlanId;
  const isUnlimited = props.plan.plan_fee === 55 && !isCanada;
  const dataForDisplay = isUnlimited ? "Unlimited*" : props.plan.data;

  const onClickPlan = () => {
    // setSelectedPlanId(props.plan.planId);
    // dispatch(updateActivationInfo({ selectedPlanId: props.plan.planId }) as any);
    dispatch(updateLocal({ selectedPlanId: props.plan.planId }) as any);
    dispatch(updateActivationInfo({ planId: props.plan.planId }) as any);
    if (props.plan.plintronPlanId) {
      dispatch(
        updateActivationInfo({
          us_plintron_plan_id: props.plan.plintronPlanId,
        }) as any
      );
    }
  };

  return (
    <WhiteCard
      $selected={isSelected}
      $selectedColor={transparentCountryColor}
      style={{
        width: "180px",
        height: "185px",
        display: "inline-block",
        textAlign: "center",
        paddingTop: "16px",
        paddingBottom: "16px",
        cursor: "pointer",
        marginRight: "32px",
        marginBottom: "16px",
        marginTop: "15px",
        ...props.style,
      }}
      onClick={onClickPlan}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "4px",
          width: "fit-content",
        }}
      >
        <DataLabel $color={countryColor} $lightColor={lightCountryColor}>
          {dataForDisplay}
        </DataLabel>
      </div>
      <PlanPrice
        color={countryColor}
        currency={currency}
        price={props.plan.plan_fee}
        timeUnit={props.plan.planTypeD}
      ></PlanPrice>
      <div style={{ position: "relative" }}>
        {isUnlimited && (
          <div
            style={{
              color: countryColor,
              fontSize: "10px",
              position: "absolute",
              width: "100%",
              textAlign: "center",
              marginTop: "-12px",
            }}
          >
            *Speed reduces after {props.plan.data}
          </div>
        )}
      </div>
      {/* <input type="radio" className={isCanada ? "radio-blue" : "radio-green"} checked={isSelected} style={{ margin: "16px auto auto auto" }} readOnly/> */}
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        {isSelected && (
          <GradientButton
            $isCanada={isCanada}
            onClick={() => props.onClickNext()}
            style={{ position: "absolute" }}
          >
            Next
          </GradientButton>
        )}
      </div>
    </WhiteCard>
  );
}

const WhiteCard = styled.div<{ $selected: boolean; $selectedColor: string }>`
  border-radius: 10px;
  box-shadow: 0 20px 40px 0
      ${(props) =>
        props.$selected ? props.$selectedColor : "rgba(0, 0, 0, 0.05)"},
    0 20px 40px 0
      ${(props) =>
        props.$selected ? props.$selectedColor : "rgba(0, 0, 0, 0.09)"};
`;

const DataLabel = styled.div<{
  $color: string;
  $lightColor: string;
  $small?: boolean;
}>`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$lightColor};
  padding: 5px 10px;
  height: fit-content;
  border-radius: 10px;
  width: fit-content;
  font-weight: 600;
  font-size: 20px;
`;

const GradientButton = styled.div<{ $isCanada: boolean }>`
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-image: ${(props) =>
    props.$isCanada
      ? "linear-gradient(#0093E9, #36C2CF)"
      : "linear-gradient(#82C36D, #82C36D)"};
  border-radius: 10px;
  width: fit-content;
`;

/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from "react";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";

interface StatesDropDownListProps {
  value: { name: string; code: string };
  onSelect: Function;
}

const StatesDropDownList: React.FC<StatesDropDownListProps> = (
  props: StatesDropDownListProps
) => {
  const [state, setState] = useState(props.value);
  const [search, setSearch] = useState("");
  const [states, setStates] = useState([
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
  ]);

  const optionSelect = (state: { name: string; code: string }) => {
    props.onSelect(state);
    setState(state);
  };

  const onClick = () => {
    if (search) {
      setSearch("");
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="w-100">
        <span>
          {props.value.code} - {props.value.name}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <InputGroup className="px-2">
          <Form.Control
            placeholder="Search States..."
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></Form.Control>
          <Button onClick={onClick}>
            <FontAwesomeIcon icon={search ? faClose : faSearch} />
          </Button>
        </InputGroup>
        <Dropdown.Divider />
        {states
          .filter((s) => s.name.toLowerCase().includes(search))
          .map((c, i) => (
            <Dropdown.Item key={i} onClick={() => optionSelect(c)}>
              <span>
                {c.code} - {c.name}
              </span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatesDropDownList;

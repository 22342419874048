import { useState } from "react";
import { proxyApi } from "../utilities/proxy-api";
import { Form } from "react-bootstrap";
import { ApiRequest } from "../types/models/ApiRequest";
import styled from "styled-components";
import { colors } from "../utilities/constants";
import { Step } from "../types/enums/Step";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import useIsMobile from "../hooks/useIsMobile";

interface IMEICheckProps {
  country: string;
}

export default function IMEICheck(props: IMEICheckProps) {
  const isMobile = useIsMobile();
  const [imei, setIMEI] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const countryColor = colors.BLUE;
  const [step, setStep] = useState(Step.First);

  const onClickCheckCompatibility = () => {
    setStep(Step.Second);
  };

  const handleIMEIChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setIMEI(value);
  };

  const verify = () => {
    setErrorMessage("IMEI must be 15 digits long.");
    setError(false);
    if (imei.length < 15) {
      setError(true);
      return;
    }

    setLoading(true);
    proxyApi.plintron
      .queryDevice(
        new ApiRequest({
          imei: imei,
        })
      )
      .then((res) => {
        if (!res.success) {
          setErrorMessage(
            "Oops! It seems that the device is not compatible with the eSIM card."
          );
          setError(true);
          setLoading(false);
          return;
        }

        setStep(Step.Third);
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage(
          "Oops! It seems that the device is not compatible with the eSIM card."
        );
        setError(true);
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <SmallContainer
      style={{
        backgroundColor: "#F7F7F7",
        borderRadius: "7px",
        position: "relative",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingRight: "20px",
        paddingLeft: "20px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Graphic
          src="/images/IMEICheck.png"
          alt="cellular phone"
          height="170px"
        />
        <>
          {step === Step.First && (
            <InnerContainer>
              <Subtitle>IMEI Check</Subtitle>
              <SmallText style={{ marginBottom: "10px" }}>
                You'll need to check your phone's compatibility with an IMEI
                check.
              </SmallText>
              <OutlineButton
                onClick={onClickCheckCompatibility}
                color={countryColor}
              >
                Check Compatibility
              </OutlineButton>
            </InnerContainer>
          )}
          {step === Step.Second && (
            <InnerContainer>
              <Subtitle>Check your device's compatibility</Subtitle>
              <SmallText style={{ marginBottom: "10px" }}>
                Dial <span style={{ fontWeight: 600 }}>*#06#</span> to get your
                phone's IMEI number or find it in your phone's settings.
              </SmallText>
              {!isMobile && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Form.Control
                    onChange={handleIMEIChange}
                    value={imei}
                    maxLength={15}
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                    disabled={loading}
                  />
                  <RedArrowButton onClick={verify}>
                    {!loading && (
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color={"#fff"}
                        size={"lg"}
                      ></FontAwesomeIcon>
                    )}
                    {loading && <Spinner variant="light" size="sm" />}
                  </RedArrowButton>
                </div>
              )}
              {error && (
                <div style={{ color: colors.RED, marginTop: "10px" }}>
                  {errorMessage}
                </div>
              )}
            </InnerContainer>
          )}
          {step === Step.Third && (
            <InnerContainer>
              <Subtitle color={colors.BLUE}>It's Compatible!</Subtitle>
              <SmallText style={{ marginBottom: "10px" }}>
                It's time to choose your plans and start enjoying eSIM freedom!
              </SmallText>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: colors.BLUE,
                }}
              >
                What to know how eSIM works?
              </div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "12px",
                  color: colors.BLUE,
                }}
              >
                Visit our FAQ
              </div>
            </InnerContainer>
          )}
        </>
      </div>
      {isMobile && step === Step.Second && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <Form.Control
            onChange={handleIMEIChange}
            value={imei}
            maxLength={15}
            style={{
              maxWidth: "500px",
              width: "100%",
              borderBottomRightRadius: "0px",
              borderTopRightRadius: "0px",
            }}
            disabled={loading}
          />
          <RedArrowButton onClick={verify}>
            {!loading && (
              <FontAwesomeIcon
                icon={faArrowRight}
                color={"#fff"}
                size={"lg"}
              ></FontAwesomeIcon>
            )}
            {loading && <Spinner variant="light" size="sm" />}
          </RedArrowButton>
        </div>
      )}
    </SmallContainer>
  );
}

const Subtitle = styled.div<{ color?: string }>`
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const OutlineButton = styled.div<{ color?: string }>`
  width: fit-content;
  padding: 5px 10px;
  border-radius: 7px;
  border-style: solid;
  border-color: ${(props) => (props.color ? props.color : "#000")};
  border-width: 2px;
  color: ${(props) => (props.color ? props.color : "#000")};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;

const RedArrowButton = styled.div`
  padding: 8px 20px;
  cursor: pointer;
  background-color: #e93f25;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 38px;
`;

const SmallContainer = styled.div`
  margin: auto;
  height: 100%;
`;

const Graphic = styled.img`
  position: absolute;
  top: -50px;
  left: -20px;

  @media (min-width: 992px) and (max-width: 1199px) {
    left: -40px;
  }

  @media (min-width: 421px) and (max-width: 767px) {
    top: -20px;
    left: -40px;
  }

  @media (max-width: 420px) {
    top: -20px;
    left: -60px;
  }
`;

const InnerContainer = styled.div`
  margin: auto 0;
  margin-left: 170px;

  @media (min-width: 421px) and (max-width: 767px) {
    margin-left: 140px;
  }

  @media (max-width: 420px) {
    margin-left: 120px;
  }
`;

import { AxiosResponse } from "axios";
import instance from "./axios";
import { constants } from "./constants";

/**
 * Checks if email exists in system
 * @param email
 */
const checkIfEmailExisting = (email: string) => {
  // Email confirmation API - new as of July 19, 2022
  // Used to test
  // JINGRONG0164@SINA.COM
  // 89302720554010901626
  const url = `/Activation/CheckIfEmailExisting?email=${email}&bizid=0&simnum=""`;
  return instance.post(url);
};

/**
 * Gets plans.
 * @param carrier
 * @param promo_code
 * @param currency
 * @param planType
 */
const getPlans = (
  carrier: string,
  promo_code: string,
  currency: string,
  planType: string
) => {
  let params;
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("azurestaticapps") ||
    window.location.href.includes("test-myesim")
  ) {
    console.log("get plans in test ENV");
    params = { currency, carrier, promocode: promo_code, testenv: "yes" };
  } else {
    params = { currency, carrier, promocode: promo_code };
  }
  // add promocode "newcomer2503":
  if (!params.promocode?.trim()) {
    params.promocode = "newcomer2503";
  }

  const url =
    planType === constants.PREPAID_PLAN
      ? "/Activation/PrepaidPlans"
      : "/Activation/PostpaidPlans";
  return instance.post(url, null, { params });
};

/**
 * Gets promo codes for partner.
 * @param promoCode
 */
const getPartnerPromoCodeAll = (bn: number) => {
  return instance.get(`/Activation/GetPartnerPromocodeAll?bizId=${bn}`);
};

const validatePortinAccountNumber = (
  accountNumber: string
): Promise<
  AxiosResponse<{
    IsSuccess: boolean;
    Message: string;
  }>
> =>
  instance.get(
    `/Activation/ValidatePortinAccountNumber?accountNumber=${accountNumber}`
  );

const checkIfAlreadyPhoneBoxCustomer = (
  phoneNumber: string
): Promise<AxiosResponse<boolean>> =>
  instance.get(
    `/Activation/CheckIfAlreadyPhoneBoxCustomer?phoneNumber=${phoneNumber}`
  );

export const api = {
  checkIfEmailExisting: checkIfEmailExisting,
  getPlans: getPlans,
  getPartnerPromoCodeAll: getPartnerPromoCodeAll,
  validatePortinAccountNumber: validatePortinAccountNumber,
  checkIfAlreadyPhoneBoxCustomer: checkIfAlreadyPhoneBoxCustomer,
};

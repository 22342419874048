import { Step } from "../../types/enums/Step";
import { DataRow } from "../../types/models/DataRow";
import { constants as reduxConstants } from "../reduxConstants";
import { constants } from "../../utilities/constants";

// all "local" data such as the step, plans, and summary information
// that we want to save in case user leaves page and returns, but do not want to send to boxone.
const initialState = {
  // previously local variables
  step: Step.First,
  OaId: undefined,
  selectedPlanId: undefined,
  canadaPlansPrepaid: [] as DataRow[],
  canadaPlansPostpaid: [] as DataRow[],
  usPlans: [] as DataRow[],
  country: constants.CANADA,
  isPostpaid: false,
  bn: 0,
  // summary data for payment pages
  summaryData: {
    planPrice: 0,
    chargeDuration: "",
    prorateDuration: "",
    taxes: 0,
    subTotal: 0,
    simCardFee: 0,
    taxRate: 0,
    prorateFee: 0,
  },
  portinCarrier: "Choose a Carrier",
  portinAccountNo: "",
  portinImei: "",
  portinPhoneNo: "",
  portinOther: "",
  isPortin: false,
  usPortInWireless: false,
  usPortInVerified: false,
  isNotAPOBox: false,
};

const localReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case reduxConstants.UPDATE_LOCAL:
      return {
        ...state,
        [Object.keys(action.payload)[0]]:
          action.payload[Object.keys(action.payload)[0]],
      };
    case reduxConstants.RESET_LOCAL_STATE:
      return initialState;
    default:
      return state;
  }
};

export default localReducer;

import styled from "styled-components";
import { colors } from "../utilities/constants";

export default function TakeMeThere() {
  return (
    <SmallContainer>
      <SubTitle>
        Explore <Highlight>gophonebox.com</Highlight> and discover more!
      </SubTitle>
      <div
        style={{ marginTop: 10 }}
        onClick={() => window.open("https://gophonebox.com/")}
      >
        <Button>Take Me There!</Button>
      </div>
    </SmallContainer>
  );
}

const SmallContainer = styled.div`
  background-image: url("/images/take-me-there-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  padding: 20px;
  height: 100%;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const SubTitle = styled.div`
  font-weight: 600;
  color: #fff;
`;

const Highlight = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

const Button = styled.div`
  width: fit-content;
  padding: 5px 10px;
  border-radius: 7px;
  background-color: #fff;
  color: ${colors.BLUE};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;

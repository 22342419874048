import "./global.scss";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Index from "./pages";
import PaymentThankYou from "./pages/payment-thank-you";
import Payment from "./pages/payment";
import ReactGA from "react-ga4";
import TagManager from "@sooro-io/react-gtm-module";
import { Crisp } from "crisp-sdk-web";
import { v7 } from "uuid";
import { triggerGoogleTagAndAnalytics } from "./functions";

const GA_TRACKING_ID = "G-1BY455LWGK";
const GTM_TRACKING_ID = "GTM-TJKD8FQ";

const tagManagerArgs = {
  gtmId: GTM_TRACKING_ID,
};

ReactGA.initialize(GA_TRACKING_ID);
TagManager.initialize(tagManagerArgs);

export default function App() {
  useEffect(() => {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID!);
    Crisp.chat.close();
    Crisp.session.reset();
    let session = v7();
    const _session: string =
      sessionStorage.getItem("guest-user-crisp-chat") ?? "";
    if (_session === "") {
      sessionStorage.setItem("guest-user-crisp-chat", session);
    } else {
      session = _session;
    }
    Crisp.setTokenId(session);
    Crisp.user.setNickname("Guest User");
  }, []);

  useEffect(() => {
    triggerGoogleTagAndAnalytics("pageview");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/payment-thank-you" element={<PaymentThankYou />} />
        <Route path="/payment" element={<Payment />} />
        {/* catch all redirect: */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

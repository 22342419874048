import { configureStore } from "@reduxjs/toolkit";
import activationInfoReducer from "./reducers/activationInfoReducer";
import localReducer from "./reducers/localReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const localPersistConfig = {
  key: "local",
  storage,
};

const persistedActivationInfoReducer = persistReducer(
  persistConfig,
  activationInfoReducer
);
const persistedLocalReducer = persistReducer(localPersistConfig, localReducer);

export const store = configureStore({
  reducer: {
    activationInfo: persistedActivationInfoReducer,
    local: persistedLocalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

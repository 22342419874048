import React from "react";
import styled from "styled-components";

interface PlanPriceProps {
  price: number;
  timeUnit: string;
  currency: string;
  color: string;
  justifyContentStart?: boolean;
}

const PlanPrice: React.FC<PlanPriceProps> = (props: PlanPriceProps) => {
  const stringToTimeUnit = (timeUnit: string) => {
    if (timeUnit === "90") {
      return "90 days";
    } else if (timeUnit === "7") {
      return "week";
    } else if (timeUnit === "14") {
      return "biweekly";
    } else if (timeUnit === "15") {
      return "15 days";
    } else {
      return "month";
    }
  };
  const dollars = Math.floor(props.price);
  const cents =
    props.price % 1 === 0
      ? ""
      : (props.price % 1).toFixed(2).toString().slice(1);
  return (
    <Layout justifyContentStart={props.justifyContentStart}>
      <PriceWrapper>
        <DollarIcon color={props.color}>$</DollarIcon>
        <Price color={props.color}>
          <Money color={props.color}>{dollars}</Money>
        </Price>
      </PriceWrapper>
      <CurrencyWrapper>
        <Currency>{props.currency}</Currency>
        <TimeUnit color={props.color}>
          {cents}/{stringToTimeUnit(props.timeUnit)}
        </TimeUnit>
      </CurrencyWrapper>
    </Layout>
  );
};

const BASIC_FONTSIZE = 15;

const Layout = styled.div<{ justifyContentStart?: boolean }>`
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContentStart ? "start" : "center"};
  align-items: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const DollarIcon = styled.h3<{ color: string }>`
  font-weight: 700;
  font-size: ${BASIC_FONTSIZE * 2}px;
  margin: 0px;
  color: ${(props) => (props.color ? props.color : "#0494CA")};
`;

const Price = styled.h3<{ color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#0494CA")};
`;

const Money = styled.span<{ color: string }>`
  font-weight: 700;
  font-size: ${BASIC_FONTSIZE * 3}px;
  color: ${(props) => (props.color ? props.color : "#0494CA")};
  margin: 0px;
`;

const CurrencyWrapper = styled.div`
  font-size: 16px;
`;

const TimeUnit = styled.h3<{ color: string }>`
  font-weight: 700;
  font-size: ${BASIC_FONTSIZE / 1.5}px;
  margin: 5px;
  color: ${(props) => (props.color ? props.color : "#0494CA")};
`;

const Currency = styled.div`
  width: 33px;
  height: 16px;
  background: #e93f25 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-left: 5px;
  text-align: center;
  font: normal normal bold 8px/15px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
`;

export default PlanPrice;
